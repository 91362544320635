import React from "react";

import {
    Datagrid,
    DeleteButton,
    EmailField,
    Filter,
    List,
    ShowButton,
    TextField,
    TextInput,
    translate,
} from "react-admin";

const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput source="email" alwaysOn />
  </Filter>
);

const UserList = ({ translate, ...props }) => (
  <List title={translate("users")} filters={<UsersFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="phoneNumber" />
      <EmailField source="email" />
      <TextField source="language" />
      <TextField source="deviceType" />
      <TextField source="appVersion" />
      <TextField source="hasCard" />
      <TextField source="banStatus" />
      <ShowButton label="" />
      {/* <EditButton label="" /> */}
      <DeleteButton label="" redirect={false} undoable={false} />
    </Datagrid>
  </List>
);

export default translate(UserList);
