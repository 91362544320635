// in src/Menu.js
import * as React from "react";
import { useSelector } from "react-redux";
import { MenuItemLink } from "react-admin";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { makeStyles } from "@material-ui/core/styles";
import FlagIcon from "@material-ui/icons/Flag";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import DescriptionIcon from "@material-ui/icons/Description";
import { useState } from "react";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { config } from "../config";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const Menu = ({ onMenuClick }) => {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const [openDropdown, setOpenDropdown] = useState({
    blog: false,
    boom: false
  });
  const classes = useStyles();

  const handleClick = item => {
    setOpenDropdown({
      ...openDropdown,
      [item]: !openDropdown[item]
    });
  };

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem button onClick={() => handleClick("blog")}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Blog" />
          {openDropdown.blog ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDropdown.blog} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.nested} button>
              <MenuItemLink
                to={config.adminPaths.articles}
                primaryText="Articles"
                leftIcon={<DescriptionIcon />}
                onClick={onMenuClick}
                style={{ width: "100%" }}
                sidebarIsOpen={open}
              />
            </ListItem>

            <ListItem className={classes.nested} button>
              <MenuItemLink
                to={config.adminPaths.locales}
                primaryText="Locales"
                style={{ width: "100%" }}
                leftIcon={<FlagIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={() => handleClick("boom")}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="BOOM" />
          {openDropdown.boom ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDropdown.boom} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.nested} button>
              <MenuItemLink
                to={config.adminPaths.boom.blog.articles}
                primaryText="Articles"
                leftIcon={<DescriptionIcon />}
                onClick={onMenuClick}
                style={{ width: "100%" }}
                sidebarIsOpen={open}
              />
            </ListItem>

            <ListItem className={classes.nested} button>
              <MenuItemLink
                to={config.adminPaths.boom.blog.locales}
                primaryText="Locales"
                style={{ width: "100%" }}
                leftIcon={<FlagIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </>
  );
};

export default Menu;
