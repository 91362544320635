import React from "react";
import {
  BooleanField,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
  required,
  TextInput,
  TextField,
  SingleFieldList,
  SimpleFormIterator,
  ArrayField,
  ChipField,
  TextArrayField,
  ArrayInput,
  Datagrid
} from "react-admin";

import { config } from "../config";

const supportedLocalesWithName = config.blog.supportedLocalesWithName;
const supportedLocales = supportedLocalesWithName.map(loc => loc.id);
const supportedCountries = config.blog.supportedCountries;
const supportedBlogCategories = config.blog.supportedBlogCategories;
const articleTypes = config.blog.articleTypes;

const getReferenceInputLocales = locale => {
  return (
    <>
      <BooleanInput defaultValue={false} source={`has_locale_${locale}`} />
      <ReferenceInput
        source={`locale_${locale}`}
        reference="locales"
        allowEmpty
        perPage={10000}
        sort={{ field: "title", order: "ASC" }}
        filter={{ language: locale }}
        fullWidth
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
    </>
  );
};

const getBooleanFieldsLocales = locale => {
  return <BooleanField source={`has_locale_${locale.toLowerCase()}`} />;
};

const getThumbnailInput = () => {
  return (
    <ImageInput
      source="image"
      label="Thumbnail image"
      accept="image/*"
      placeholder={<p>Drop your file here</p>}
      defaultValue={null}
      validate={[required()]}
      fullWidth
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  );
};

const getRelatedPhotosInput = () => {
  return (
    <>
      <ImageInput
        source="related_pictures"
        label="Related pictures"
        accept="image/*"
        placeholder={<p>Drop your file here</p>}
        defaultValue={null}
        multiple={true}
        fullWidth
      >
        <ImageField source="src" src="url" title="title" />
      </ImageInput>
      <ArrayInput source="related_pictures">
        <SimpleFormIterator>
          <TextInput source="src" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
const getOgImageInput = () => {
  return (
    <ImageInput
      source="og_image"
      label="OG Image"
      accept="image/*"
      placeholder={<p>Drop your file here</p>}
      defaultValue={null}
      validate={[required()]}
      fullWidth
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  );
};

export {
  supportedLocales,
  supportedCountries,
  supportedLocalesWithName,
  supportedBlogCategories,
  articleTypes,
  getReferenceInputLocales,
  getBooleanFieldsLocales,
  getThumbnailInput,
  getOgImageInput,
  getRelatedPhotosInput
};
