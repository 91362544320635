import React from "react";

import {
  Create,
  FormTab,
  required,
  TabbedForm,
  TextInput,
  translate,
  SelectInput
} from "react-admin";

import {
  getReferenceInputLocales,
  supportedLocales,
  articleTypes
} from "../../../../utils/localesHelper.jsx";

const PostsCreate = ({ translate, ...props }) => (
  <Create title={translate("createNewPost")} {...props}>
    <TabbedForm>
      <FormTab label={translate("generalInfo")}>
        <TextInput
          label="Title (len pre nás)"
          source="title"
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="type"
          choices={articleTypes}
          validate={[required()]}
          defaultValue={"ticketing"}
          disabled
        />

        {supportedLocales.map(loc => getReferenceInputLocales(loc))}
      </FormTab>
    </TabbedForm>
  </Create>
);

export default translate(PostsCreate);
