import {merchantsMockData, usersMockData} from "./mockData";
import {firebaseProvider} from "./firebase";

export const superDataProvider = (type, resource, params) => {
  switch (resource) {
    case "trisbee/users":
      return new Promise(resolve => resolve(usersMockData));

    case "trisbee/merchants":
      return new Promise(resolve => resolve(merchantsMockData));

    case "articles":
      return firebaseProvider(type, "articles", params);

    case "locales":
      return firebaseProvider(type, "locales", params);

    case "boom/blog/locales":
      return firebaseProvider(type, "locales", params);

    case "boom/blog/articles":
      return firebaseProvider(type, "articles", params);

    default:
      return null;
  }
};
