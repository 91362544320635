import React from "react";

import {
  BooleanInput,
  Create,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  translate
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

import {
  articleTypes,
  supportedBlogCategories,
  supportedCountries,
  supportedLocalesWithName,
  getThumbnailInput,
  getOgImageInput,
  getRelatedPhotosInput
} from "../../../../utils/localesHelper.jsx";

const LocalesCreate = ({ translate, ...props }) => (
  <Create title="Locales" {...props}>
    <TabbedForm>
      <FormTab label={translate("generalInfo")}>
        <SelectInput
          source="type"
          choices={articleTypes}
          defaultValue={"ticketing"}
          disabled
          validate={[required()]}
        />
        <SelectInput
          source="category"
          choices={supportedBlogCategories}
          defaultValue={"rest"}
          disabled
          validate={[required()]}
        />

        <SelectInput
          source="country"
          choices={supportedCountries}
          validate={[required()]}
          defaultValue={"all"}
          disabled
        />

        <SelectInput
          source="language"
          choices={supportedLocalesWithName}
          validate={[required()]}
        />

        {getThumbnailInput()}
        {getRelatedPhotosInput()}

        <BooleanInput defaultValue={true} source="isvisible" />
        <BooleanInput defaultValue={false} source="deleted" />
      </FormTab>
      <FormTab label={translate("articleContent")}>
        <TextInput source="slug" validate={[required()]} fullWidth />
        <TextInput source="title" validate={[required()]} fullWidth />
        {getOgImageInput()}
        <TextInput source="metaDescription" fullWidth />
        <TextInput source="perex" validate={[required()]} fullWidth />
        <RichTextInput source="content" validate={[required()]} />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default translate(LocalesCreate);
