import React from "react";
import {Admin, resolveBrowserLocale, Resource} from "react-admin";

// Custom layout, dashboard
import MyLayout from "./components/MyLayout";
import Dashboard from "./resources/dashboard";

// Super data provider (firebase + other data providers)
import {superDataProvider} from "./api/superDataProvider";

// Auth
import {LoginPage} from "./components/LoginPage";
import {authProvider} from "./api/authProvider";

// Translation: Default RA messages && custom components
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./languages/englishMessages";
import czechMessages from "./languages/czechMessages";

/*---------------------------------------------- Shared ----------------------------------------------*/
import PostsIcon from "@material-ui/icons/PostAdd";
import PostsNewCreate from "./resources/blog/posts/postsCreate";
import PostsNewEdit from "./resources/blog/posts/postsEdit";
import PostsNewList from "./resources/blog/posts/postsList";
import LocalesCreate from "./resources/blog/locales/localesCreate";
import LocalesEdit from "./resources/blog/locales/localesEdit";
import LocalesList from "./resources/blog/locales/localesList";

/*---------------------------------------------- Trisbee ----------------------------------------------*/
import UsersIcon from "@material-ui/icons/SupervisorAccount";
import TrisbeeUserList from "./resources/trisbee/users/userList";
import TrisbeeUserShow from "./resources/trisbee/users/userShow";
import TrisbeeUserEdit from "./resources/trisbee/users/userEdit";
import MerchantsIcon from "@material-ui/icons/Storefront";
import TrisbeeMerchantList from "./resources/trisbee/merchants/merchantList";
import TrisbeeMerchantShow from "./resources/trisbee/merchants/merchantShow";
import TrisbeeMerchantEdit from "./resources/trisbee/merchants/merchantEdit";

/*---------------------------------------------- BOOM ----------------------------------------------*/
import BoomPostsNewCreate from "./resources/boom/blog/posts/postsCreate";
import BoomPostsNewEdit from "./resources/boom/blog/posts/postsEdit";
import BoomPostsNewList from "./resources/boom/blog/posts/postsList";
import BoomLocalesCreate from "./resources/boom/blog/locales/localesCreate";
import BoomLocalesEdit from "./resources/boom/blog/locales/localesEdit";
import BoomLocalesList from "./resources/boom/blog/locales/localesList";

/* Store locale (en/cs) => auto filling for default locale from user browser */
if (!localStorage.getItem("locale")) {
  localStorage.setItem("locale", resolveBrowserLocale());
}

const i18nProvider = polyglotI18nProvider(
  locale => (locale === "cs" ? czechMessages : englishMessages),
  localStorage.getItem("locale") // Default locale
);

const AdminContainer = () => {
  return (
    <Admin
      title="Trisbee BackOffice"
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={superDataProvider}
      loginPage={LoginPage}
      layout={MyLayout}
      dashboard={Dashboard}
    >
      <Resource
        name="boom/blog/locales"
        list={BoomLocalesList}
        edit={BoomLocalesEdit}
        create={BoomLocalesCreate}
        icon={PostsIcon}
      />

      <Resource
        name="boom/blog/articles"
        list={BoomPostsNewList}
        edit={BoomPostsNewEdit}
        create={BoomPostsNewCreate}
        icon={PostsIcon}
      />

      <Resource
        name="articles"
        list={PostsNewList}
        edit={PostsNewEdit}
        create={PostsNewCreate}
        icon={PostsIcon}
      />

      <Resource
        name="locales"
        list={LocalesList}
        edit={LocalesEdit}
        create={LocalesCreate}
        icon={PostsIcon}
      />

      <Resource
        name="trisbee/users"
        list={TrisbeeUserList}
        edit={TrisbeeUserEdit}
        show={TrisbeeUserShow}
        icon={UsersIcon}
      />

      <Resource
        name="trisbee/merchants"
        list={TrisbeeMerchantList}
        edit={TrisbeeMerchantEdit}
        show={TrisbeeMerchantShow}
        icon={MerchantsIcon}
      />
    </Admin>
  );
};

export default AdminContainer;
