import { firebaseAuthLoaded, firebaseAuth } from "./firebase";
import { config } from "../config";

export const requestSecured = async (url, options = {}) => {
  // Make sure firebase auth is already loaded
  await firebaseAuthLoaded();

  // Guard - just to be sure app can't crash
  if (!firebaseAuth || !firebaseAuth.currentUser) {
    return new Promise((resolve, reject) => {
      reject("firebaseAuth does not have current user");
    });
  }

  // Get ID token of current user
  const token = await firebaseAuth.currentUser.getIdToken();
  return fetch(`${config.apiUrl}/${url}`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      ...options.headers
    }),
    ...options
  }).then(function(response) {
    console.log("apicko:", response.clone().json());
    return response.clone().json();
  });
};
