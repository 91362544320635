import "firebase/auth";
import firebase from "firebase/app";
import { config } from "../config";

import { FirebaseDataProvider } from "react-admin-firebase";

// Prevents multiple firebase app initialization
const firebaseClient = !firebase.apps.length
  ? firebase.initializeApp(config.firebase)
  : firebase.app();

const firebaseAuthLoaded = () =>
  new Promise(resolve => {
    firebaseClient.auth().onAuthStateChanged(resolve);
  });

const firebaseAuth = firebaseClient.auth();
// React admin firebase exports
const options = {};
export const firebaseProvider = FirebaseDataProvider(config.firebase, options);

export { firebaseAuth, firebaseAuthLoaded };
