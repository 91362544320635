import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  translate
} from "react-admin";
import ListPreviewButton from "../../../../components/listPreviewButton";

const PostsFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <TextInput source="title" alwaysOn />
    </Filter>
  );
};

const LocalesList = ({ translate, ...props }) => {
  return (
    <List
      title="Locales"
      filters={<PostsFilter />}
      filter={{ type: "ticketing" }}
      {...props}
      sort={{ field: "createdate", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="country" />
        <TextField source="language" />
        <TextField source="createdby" />
        <DateField source="createdate" />
        <DateField source="lastupdate" />
        <BooleanField source="isvisible" />
        <BooleanField source="deleted" />

        <ListPreviewButton {...props} />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};

export default translate(LocalesList);
