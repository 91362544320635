import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  SelectInput,
  TextField,
  TextInput,
  translate
} from "react-admin";
import ListPreviewButton from "../../../components/listPreviewButton";
import {articleTypes} from "../../../utils/localesHelper";

const PostsFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <TextInput source="title" alwaysOn />
      <SelectInput
        source="type"
        choices={articleTypes}
        alwaysOn
      />
    </Filter>
  );
};

const LocalesList = ({ translate, ...props }) => {
  return (
    <List
      title="Locales"
      filters={<PostsFilter />}
      filterDefaultValues={{
        type: 'blog'
      }}
      {...props}
      sort={{ field: "createdate", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="category" />
        <TextField source="country" />
        <TextField source="language" />
        <TextField source="createdby" />
        <DateField source="createdate" />
        <DateField source="lastupdate" />
        <BooleanField source="isvisible" />
        <BooleanField source="deleted" />

        <ListPreviewButton {...props} />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};

export default translate(LocalesList);
