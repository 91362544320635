import React from "react";

import {ArrayField, Datagrid, DateField, Show, SimpleShowLayout, TextField, translate,} from "react-admin";

const MerchantShow = ({ translate, ...props }) => (
  <Show title={translate("merchantDetail")} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="referral" />
      <TextField source="status" />
      <TextField source="venueId" />
      <TextField source="amountWithoutKyc" />
      <TextField source="amountWithKyc" />
      <ArrayField source="history">
        <Datagrid>
          <DateField source="dateCreated" sortable={false} />
          <DateField source="dateEnd" sortable={false} />
          <TextField source="amountWithoutKyc" sortable={false} />
          <TextField source="amountWithKyc" sortable={false} />
          <TextField source="notes" sortable={false} />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default translate(MerchantShow);
