import React from "react";

import {
    ArrayField,
    Datagrid,
    DateField,
    EmailField,
    ImageField,
    Show,
    SimpleShowLayout,
    TextField,
    translate,
} from "react-admin";

// Pořešit překlady a smazat všechny labels!!!
const UserShow = ({ translate, ...props }) => (
  <Show title={translate("userDetail")} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="phoneNumber" />
      <EmailField source="email" />
      <ImageField source="frontId" />
      <ImageField source="selfieWithId" />
      <TextField source="language" />
      <TextField source="deviceType" />
      <TextField source="appVersion" />
      <TextField source="hasCard" />
      <TextField source="status" />
      <TextField source="available" />
      <TextField source="limit" />
      <TextField source="spend" />
      <TextField source="paid" />
      <ArrayField source="transactionHistory">
        <Datagrid>
          <TextField source="amount" sortable={false} />
          <DateField source="dateCreated" sortable={false} />
          <DateField source="dateEnd" sortable={false} />
          <TextField source="notes" sortable={false} />
        </Datagrid>
      </ArrayField>
      <ArrayField source="banHistory">
        <Datagrid>
          <TextField source="banId" sortable={false} />
          <DateField source="banCreatedAt" sortable={false} />
          <TextField source="banStatus" sortable={false} />
          <TextField source="banReason" sortable={false} />
          <TextField source="banAuthorizedBy" sortable={false} />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default translate(UserShow);
