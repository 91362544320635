import React from "react";
import { translate } from "react-admin";
import styled from "styled-components";
import { config } from "../config";

const ShowPreviewButton = ({ translate, slug, language, country, type }) => {
  const fbDebugger = "https://developers.facebook.com/tools/debug/?q=";
  const linkedinInspect = "https://www.linkedin.com/post-inspector/inspect/";
  const twitterCardValidator = "https://cards-dev.twitter.com/validator";

  let fullArticleUrl = "";

  /* In case of blog article url structure is trisbee.com/[lang]/[country]/blog/[slug] 
  In case of boom article url structure is goforboom.com/[lang]/blog/[slug] */
  if (type === "blog") {
    let articleUrl = `
    ${language}/
    ${config.blog.countryToSlug[country]}/
    ${process.env.REACT_APP_BLOG}/
    ${slug}`;

    articleUrl = articleUrl.replace(/\s/g, "").replace(/\/\//g, "/");
    fullArticleUrl = `${process.env.REACT_APP_COMPANY_WEB_URL}/${articleUrl}`;
  } else if (type === "ticketing") {
    let articleUrl = `
    ${language}/
    ${process.env.REACT_APP_BLOG}/
    ${slug}`;

    articleUrl = articleUrl.replace(/\s/g, "").replace(/\/\//g, "/");
    fullArticleUrl = `${process.env.REACT_APP_BOOM_ROOT_URL}/${articleUrl}`;
  }

  return (
    <Wrapper>
      <PreviewButton target="_blank" href={fullArticleUrl}>
        {translate("previewOnWeb")}
      </PreviewButton>
      <FbDebuggerButton target="_blank" href={`${fbDebugger}${fullArticleUrl}`}>
        {translate("showFbDebugger")}
      </FbDebuggerButton>
      <LinkedinButton
        target="_blank"
        href={`${linkedinInspect}${encodeURIComponent(fullArticleUrl)}`}
      >
        {translate("showLinkedinInspect")}
      </LinkedinButton>
      <TwitterButton target="_blank" href={twitterCardValidator}>
        {translate("showTwitterValidator")}
      </TwitterButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const BasicButton = styled.a`
  text-decoration: none;
  padding: 0.7rem 1rem;
  font-size: 0.875rem;
  color: #fff;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  margin-left: 20px;
`;

const PreviewButton = styled(BasicButton)`
  background-color: #009971;
`;
const FbDebuggerButton = styled(BasicButton)`
  background-color: #4267b2;
`;
const LinkedinButton = styled(BasicButton)`
  background-color: #2867b2;
`;

const TwitterButton = styled(BasicButton)`
  background-color: #1da1f2;
`;

export default translate(ShowPreviewButton);
