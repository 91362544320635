import React from "react";

import {
  Datagrid,
  DeleteButton,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  translate
} from "react-admin";
import ListPreviewButton from "../../../../components/listPreviewButton";
import {
  getBooleanFieldsLocales,
  supportedLocales
} from "../../../../utils/localesHelper";

const PostsFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <TextInput source="title" alwaysOn />
    </Filter>
  );
};

const PostsList = ({ translate, ...props }) => {
  console.log("supported langs", supportedLocales);

  return (
    <List
      title="POSTS"
      filters={<PostsFilter />}
      filter={{ type: "ticketing" }}
      {...props}
      sort={{ field: "createdate", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" />
        {supportedLocales.map(loc => getBooleanFieldsLocales(loc))}
        <ListPreviewButton {...props} />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};

export default translate(PostsList);
