import { requestSecured } from "../../requestSecured";

// currently mocking - waiting for BE
export const requestUserRole = () => {
  // localhost - without API login for now
  if ("production" !== process.env.REACT_APP_ENV) {
    return new Promise(resolve => {
      resolve({ data: { role: "admin" } });
    });
  }
  return requestSecured("login");
};
