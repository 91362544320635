import React from "react";
import { useLocale, useSetLocale } from "react-admin";
import styled from "styled-components";

const LocaleSwitcher = () => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  return locale === "cs" ? (
    <Button type="button" onClick={() => setLocale("en")}>
      English
    </Button>
  ) : (
    <Button onClick={() => setLocale("cs")}>Čeština</Button>
  );
};

const Button = styled.button`
  text-decoration: none;
  padding: 0.3rem 1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #1976d2;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  border: none;
  margin-left: 1rem;
`;

export default LocaleSwitcher;
