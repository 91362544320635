import React, { useState } from "react";
import { useLogin } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import logo from "./logo.svg";
import * as firebase from "firebase/app";
import "firebase/auth";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [errorCause, setErrorCause] = useState();
  const classes = useLoginPageStyles();
  const login = useLogin();

  const handleLoginGoogle = () => {
    setError(false);
    setErrorCause(undefined);
    setLoading(true);
    const provider = new firebase.auth.GoogleAuthProvider();

    login({ provider })
      .catch(response => {
        setError(true);

        if (response) {
          setErrorCause(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <img src={logo} alt={"Trisbee"} />
        <h1 className={classes.title}>BackOffice</h1>
      </div>

      <div className={classes.actionWrapper}>
        <button className={classes.button} onClick={handleLoginGoogle}>
          {loading ? "Přihlašuji..." : "Přihlásit se přes Google"}
        </button>

        <div className={classes.errorWrapper}>
          {error && (
            <p className={classes.textError}>
              Něco se pokazilo, zkust to znovu.
            </p>
          )}
          {errorCause && (
            <p className={classes.textError}>Důvod: {errorCause}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const useLoginPageStyles = makeStyles({
  wrapper: {
    padding: "0 5%",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#009b77"
  },
  titleWrapper: {
    width: "100%",
    maxWidth: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  title: {
    margin: "8px 2px 0 0",
    color: "#fff",
    fontSize: "28px",
    fontFamily: "sans-serif",
    fontWeight: 400,
    textAlign: "right"
  },
  actionWrapper: {
    marginTop: "3vh",
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    padding: "8px 16px",
    minWidth: "200px",
    borderRadius: "5px",
    border: "solid 2px #fff",
    backgroundColor: "#008a66",
    color: "#fff",
    fontFamily: "sans-serif",
    fontSize: "20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#46b181"
    }
  },
  errorWrapper: {
    minHeight: "60px"
  },
  textError: {
    textAlign: "center",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "600",
    fontFamily: "sans-serif"
  }
});

