import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import { firebaseAuth, firebaseAuthLoaded } from "./firebase";
import { requestUserRole } from "./calls/user/role";

export const authProvider = async (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { provider } = params;

    // Authentication
    const responseSignIn = await firebaseAuth
      .signInWithPopup(provider)
      .catch(response => {
        return {
          error: (response && response.message) || "Problém spojení s Firebase"
        };
      });

    // Error handling - authentication error
    if (responseSignIn && responseSignIn.error) {
      return new Promise((resolve, reject) => {
        reject(responseSignIn.error);
      });
    }

    // Authorization
    const responseUserRole = await requestUserRole().catch(() => {
      return {
        error: "Něco se pokazilo při autorizaci uživatele"
      };
    });

    // Error handling - authorization error
    if (responseUserRole.error) {
      return new Promise((resolve, reject) => {
        reject(responseUserRole.error);
      });
    }

    // Error handling - user is not admin
    if (responseUserRole.data.role !== "admin") {
      return new Promise((resolve, reject) => {
        reject("Uživatel není administrátor - špatný účet?");
      });
    }

    // success
    return new Promise(resolve => {
      resolve();
    });
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    firebaseAuth.signOut();
    return Promise.resolve();
  }

  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem("username");
      return Promise.reject();
    }

    return Promise.resolve();
  }

  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return firebaseAuthLoaded().then(() => {
      // This is just for test purposes
      // @TODO: remove when not needed
      //console.log("___ AUTH CHECK ___");
      //logToken();

      if (firebaseAuth.currentUser) {
        return Promise.resolve();
      }
      return Promise.reject();
    });
  }
  return Promise.reject("Unknown method");
};
