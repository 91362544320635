import React from "react";
import { Title as SEO, translate } from "react-admin";
import styled from "styled-components";
import { dashboardMockData } from "../api/mockData";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Card = styled.div`
  margin: 2rem 1.5rem 0.5rem 1.5rem;
  width: 300px;
  background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%);
  padding: 2rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 10px;
`;

const Value = styled.h2`
  color: white;
  margin: 0 0 0.5rem 0;
  font-size: 3rem;
`;

const Description = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
`;

const Dashboard = ({ translate }) => {
  return (
    <Container>
      <SEO title="Dashboard" />
      <Card>
        <Value>{dashboardMockData.countOfVenues}</Value>
        <Description>{translate("countOfVenues")}</Description>
      </Card>

      <Card>
        <Value>{dashboardMockData.countOfUsers}</Value>
        <Description>{translate("countOfUsers")}</Description>
      </Card>

      <Card>
        <Value>{dashboardMockData.countOfUsersWithEmail}</Value>
        <Description>{translate("countOfUsersWithEmail")}</Description>
      </Card>

      <Card>
        <Value>{dashboardMockData.countOfCards}</Value>
        <Description>{translate("countOfCards")}</Description>
      </Card>

      <Card>
        <Value>{dashboardMockData.countOfExpiredCards}</Value>
        <Description>{translate("countOfExpiredCards")}</Description>
      </Card>

      <Card>
        <Value>{dashboardMockData.countOfUserIds}</Value>
        <Description>{translate("countOfUserIds")}</Description>
      </Card>
    </Container>
  );
};

export default translate(Dashboard);
