import React from "react";

import {
  DateInput,
  Edit,
  FormTab,
  required,
  TabbedForm,
  TextInput,
  TopToolbar,
  translate,
  SelectInput
} from "react-admin";
import {
  articleTypes,
  getReferenceInputLocales,
  supportedLocales
} from "../../../../utils/localesHelper.jsx";

import ShowPreviewButton from "../../../../components/showPreviewButton";

const PostShowActions = ({ data }) => {
  /* It's takes a couple seconds, when data are received from firestore,
   so we need prevent error because of initial undefined "slug" && "isvisible" */
  const slug = data !== undefined ? data.slug : "";
  const isVisible = data !== undefined ? data.isvisible : "";

  return (
    <>
      <TopToolbar>
        {slug && isVisible && <ShowPreviewButton slug={slug} />}
      </TopToolbar>
    </>
  );
};

const PostsEdit = ({ translate, ...props }) => {
  return (
    <Edit
      title={translate("editArticle")}
      undoable={false}
      redirect={false}
      actions={<PostShowActions />}
      {...props}
    >
      <TabbedForm>
        <FormTab label="Locales">
          <TextInput source="id" label="id" disabled />
          <TextInput
            label="Title (len pre nás)"
            source="title"
            validate={[required()]}
            fullWidth
          />
          <SelectInput
            source="type"
            choices={articleTypes}
            validate={[required()]}
            defaultValue={"ticketing"}
            disabled
          />
          {supportedLocales.map(loc => getReferenceInputLocales(loc))}
        </FormTab>
        <FormTab label="Created/Edited">
          <DateInput source="createdate" />
          <TextInput source="createdby" />
          <DateInput source="lastupdate" />
          <TextInput source="updatedby" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default translate(PostsEdit);
