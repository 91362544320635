import React from "react";

import {Edit, SimpleForm, TextInput,} from "react-admin";

const MerchantEdit = props => (
    <Edit title="Editace článku" undoable={false} {...props}>
        <SimpleForm>
            <TextInput source="id" label="Identifikační číslo" disabled />
            <TextInput source="email" label="E-mail" />
            <TextInput source="phoneNumber" label="Telefonní číslo" />
            <TextInput source="referral" label="Referral" />
        </SimpleForm>
    </Edit>
);

export default MerchantEdit;
