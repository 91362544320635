export const config = {
    apiUrl: process.env.REACT_APP_API_HOST,
    firebase: {
        apiKey: "AIzaSyAry0Q2ffvBkQZ8o04xMOPgBIQ73iE24tg",
        authDomain: "trisbee-test.firebaseapp.com",
        databaseURL: "https://trisbee-test.firebaseio.com",
        projectId: "trisbee-test",
        storageBucket: "trisbee-test.appspot.com",
        messagingSenderId: "835025029844",
        appId: "1:835025029844:web:a373651300ea40db230941"
    },
    supportedCurrencies: [
        { id: "EUR", name: "EUR" },
        { id: "CZK", name: "CZK" },
        { id: "USD", name: "USD" },
        { id: "GBP", name: "GBP" }
    ],
    adminPaths: {
        boom: {
            blog: {
                locales: "/boom/blog/locales",
                articles: "/boom/blog/articles"
            }
        },
        articles: "/articles",
        locales: "/locales"
    },
    blog: {
        supportedLocalesWithName: [
            { id: "cs", name: "Čeština" },
            { id: "en", name: "English" },
            { id: "es", name: "Spanish" },
            { id: "de", name: "Deutch" },
            { id: "it", name: "Italian" },
            { id: "ro", name: "Romanian" }
        ],
        supportedCountries: [
            { id: "cz", name: "Czechia" },
            { id: "es", name: "Spain" },
            { id: "it", name: "Italy" },
            { id: "ro", name: "Romania" },
            { id: "all", name: "All" }
        ],

        countryToSlug: {
            cz: "",
            es: "spain",
            it: "italy",
            ro: "romania"
        },

        supportedBlogCategories: [
            { id: "news", name: "Novinky" },
            { id: "tips", name: "Tipy" },
            { id: "stories", name: "Příběhy" },
            { id: "jobs", name: "Práce" },
            { id: "rest", name: "Ostatní" }
        ],

        articleTypes: [
            { id: "blog", name: "Blog" },
            { id: "press_message", name: "Tiskova Zprava" },
            { id: "ticketing", name: "Ticketing" }
        ]
    },
    boom: {
        supportedLocalesWithName: [
            { id: "CS", name: "Čeština" },
            { id: "EN", name: "English" },
            { id: "ES", name: "Spanish" },
            { id: "DE", name: "Deutch" },
            { id: "IT", name: "Italian" },
            { id: "RO", name: "Romanian" }
        ]
    }
};
