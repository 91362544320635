export const usersMockData = {
  data: [
    {
      // User detail
      id: "daa6941f-8e76-4820-81ac-6e225fa97064",
      name: "Jan Endel",
      phoneNumber: "+420773020089",
      email: "jan@endel.cz",
      frontId:
        "https://specials-images.forbesimg.com/imageserve/5d93c74324fbf10007b8a865/960x0.jpg",
      selfieWithId: "https://www.cashwise.co.nz/images/selfie.jpg",
      language: "CS",
      deviceType: "Android",
      appVersion: "12.2.1",
      hasCard: "ano",

      // Ban handling (THIS IS FOR REQUEST!!!!)
      banToPhoneNumber: "420774067835",
      banStatus: "Neaktivní",
      banReason: "suspicious behaviour",
      banAuthorizedBy: "landsman",

      // Ban history
      banHistory: [
        {
          banId: "d3503bcf-c258-4bac-bc34-9b6964936a4d",
          banCreatedAt: "Sun, 03 May 2020 16:09:17 +0000",
          banStatus: "ACTIVE",
          banReason: "suspicious behaviour - testing",
          banAuthorizedBy: "landsman",
        },
        {
          banId: "03d59cb5-8cb7-4d91-8303-2b4e3a3ed996",
          banCreatedAt: "Sun, 03 May 2020 16:10:02 +0000",
          banStatus: "DELETED",
          banReason: "suspicious behaviour - testing 2",
          banAuthorizedBy: "landsman",
        },
      ],

      // Transaction history
      status: "ok",
      available: 285.0,
      limit: 6000.0,
      spend: 7030.0,
      paid: 1315.0,
      transactionHistory: [
        {
          amount: 6000.0,
          dateCreated: "Fri, 29 Nov 2019 15:40:50 +0000",
          dateEnd: "Sun, 29 Dec 2019 15:39:57 +0000",
          notes: "NULL",
        },
      ],
    },
  ],
  total: 1,
};

export const merchantsMockData = {
  data: [
    {
      // Merchant detail
      id: "0dc2ae1f-33e0-4583-ab28-19c675ab52e7",
      email: "podpora@trisbee.com",
      phoneNumber: "774000999",
      referral: "web",
      status: "ok",

      // Transaction history
      venueId: 1107,
      amountWithoutKyc: 10000.0,
      amountWithKyc: 10000.0,
      history: [
        {
          dateCreated: "Fri, 20 Mar 2020 12:11:32 +0000",
          dateEnd: null,
          amountWithoutKyc: 10000.0,
          amountWithKyc: 10000.0,
          notes: "testing studioartcz venue",
        },
      ],
    },
  ],
  total: 1,
};

export const dashboardMockData = {
  countOfVenues: 4,
  countOfUsers: 8,
  countOfUsersWithEmail: 8,
  countOfCards: 0,
  countOfExpiredCards: 0,
  countOfUserIds: 0,
};
