import React from "react";
import { translate } from "react-admin";
import styled from "styled-components";
import { config } from "../config";

const ListPreviewButton = ({ translate, ...props }) => {
  /* It's takes a couple seconds, when data are received from firestore,
   so we need prevent error because of initial undefined "slug" && "isvisible" */
  const slug = props !== undefined ? props.record.slug : "";
  const isVisible = props !== undefined ? props.record.isvisible : "";

  let fullArticleUrl = "";

  /* In case of blog article url structure is trisbee.com/[lang]/[country]/blog/[slug] 
  In case of boom article url structure is goforboom.com/[lang]/blog/[slug] */
  if (props.record.type === "blog") {
    let articleUrl = `
    ${props.record.language}/
    ${config.blog.countryToSlug[props.record.country]}/
    ${process.env.REACT_APP_BLOG}/
    ${slug}`;
    articleUrl = articleUrl.replace(/\s/g, "").replace(/\/\//g, "/");
    fullArticleUrl = `${process.env.REACT_APP_COMPANY_WEB_URL}/${articleUrl}`;
  } else if (props.record.type === "ticketing") {
    let articleUrl = `
    ${props.record.language}/
    ${process.env.REACT_APP_BLOG}/
    ${slug}`;
    articleUrl = articleUrl.replace(/\s/g, "").replace(/\/\//g, "/");
    fullArticleUrl = `${process.env.REACT_APP_BOOM_ROOT_URL}/${articleUrl}`;
  }

  return (
    <>
      {isVisible && (
        <CustomListPreviewButton target="_blank" href={fullArticleUrl}>
          {translate("preview")}
        </CustomListPreviewButton>
      )}
    </>
  );
};

export const CustomListPreviewButton = styled.a`
  text-decoration: none;
  padding: 0.3rem 1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: #1976d2;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
`;

export default translate(ListPreviewButton);
