import React from "react";

import {
  BooleanInput,
  DateInput,
  Edit,
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  TopToolbar,
  translate
} from "react-admin";

import RichTextInput from "ra-input-rich-text";

import ShowPreviewButton from "../../../components/showPreviewButton";

import {
  articleTypes,
  supportedBlogCategories,
  supportedCountries,
  supportedLocalesWithName,
  getThumbnailInput,
  getOgImageInput,
  getRelatedPhotosInput
} from "../../../utils/localesHelper.jsx";

const PostShowActions = ({ data }) => {
  /* It's takes a couple seconds, when data are received from firestore,
   so we need prevent error because of initial undefined */
  const slug = data !== undefined ? data.slug : "";
  const isVisible = data !== undefined ? data.isvisible : "";
  const language = data !== undefined ? data.language : "";
  const country = data !== undefined ? data.country : "";
  const type = data !== undefined ? data.type : "";

  return (
    <>
      <TopToolbar>
        {slug && isVisible && language && type && country && (
          <>
            <ShowPreviewButton
              slug={slug}
              country={country}
              language={language}
              type={type}
            />
          </>
        )}
      </TopToolbar>
    </>
  );
};

const LocalesEdit = ({ translate, ...props }) => (
  <Edit
    title={translate("editArticle")}
    undoable={false}
    redirect={false}
    actions={<PostShowActions />}
    {...props}
  >
    <TabbedForm>
      <FormTab label={translate("articleContent")}>
        <TextInput source="id" label="id" disabled />
        <TextInput source="createdby" disabled />

        <SelectInput
          source="language"
          choices={supportedLocalesWithName}
          validate={[required()]}
        />

        <SelectInput
          source="country"
          choices={supportedCountries}
          validate={[required()]}
        />

        <SelectInput
          source="type"
          choices={articleTypes}
          validate={[required()]}
          defaultValue={"blog"}
          disabled
        />

        <SelectInput
          source="category"
          choices={supportedBlogCategories}
          validate={[required()]}
        />
        <TextInput source="slug" fullWidth />

        {getThumbnailInput()}
        {getRelatedPhotosInput()}

        <TextInput source="title" validate={[required()]} fullWidth />
        <TextInput source="perex" validate={[required()]} fullWidth />
        <RichTextInput source="content" validate={[required()]} />
        <DateInput source="createdate" />
        <DateInput source="lastupdate" />
        <BooleanInput defaultValue={true} source="isvisible" />
        <BooleanInput defaultValue={false} source="deleted" />
      </FormTab>
      <FormTab label="SEO">
        {getOgImageInput()}
        <TextInput source="metaDescription" fullWidth />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default translate(LocalesEdit);
