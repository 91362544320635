import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LocaleSwitcherButton from './localeSwitcherButton';
import styled from "styled-components";

const MyAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo>BackOffice</Logo>
      <Spacer />
      <LocaleSwitcherButton />
    </AppBar>
  );
};

const Logo = styled.div`
  font-size: 1.35rem;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-weight: bold;
  user-select: none;
`;

const Spacer = styled.div`
  display: flex;
  flex: 1;
`;

const useStyles = makeStyles({
  title: {
    display: "flex",
    fontSize: "1.1rem",
    fontFamily: "'Roboto', Helvetica, Arial, sans-serif",
    fontWeight: "bold",
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    display: "flex",
    flex: 1,
  },
});

export default MyAppBar;
